var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('v-container',{staticClass:"pa-0",attrs:{"fill-height":"","fluid":""}},[_c('v-row',{staticClass:"fill-height",attrs:{"no-gutters":""}},[_c('v-col',{attrs:{"cols":"12"}},[_c('v-container',{staticClass:"d-flex align-start fill-height"},[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-card',{staticClass:"pl-15 mb-16",attrs:{"width":"500px","elevation":"0"}},[_c('v-card-title',{staticClass:"login-title"},[_vm._v(" "+_vm._s(_setup.t('Title_.page.loginPhoneNumber'))+" ")]),_c('v-card-subtitle',{staticClass:"login-subtitle mb-16"},[_vm._v(" "+_vm._s(_setup.t('Title_.subTitle.loginValidateCode'))+" "),_c('strong',[_vm._v(" "+_vm._s(_setup.ownerPhone)+" ")])]),_c('v-card-text',[_c('v-form',{attrs:{"disabled":_setup.authenticationStore.isAuthenticationLoading},on:{"submit":function($event){$event.preventDefault();return _setup.loginWithPhoneNumber()}},model:{value:(_setup.valid),callback:function ($$v) {_setup.valid=$$v},expression:"valid"}},[_c('v-text-field',{attrs:{"error":_setup.showBackendValidationError,"rules":[
                      _vm.$rules.fieldRequired(
                        _setup.t('Msg_.rules.verificationCode') + '*'
                      ),
                      _vm.$rules.totalSize(6, _setup.t('Msg_.rules.codeTotalDigits')),
                    ],"clearable":"","required":"","label":_setup.t('Msg_.verificationCode'),"placeholder":_setup.t('Msg_.verificationCode'),"outlined":"","maxlength":"6"},on:{"keyup":_setup.onlyNumbers},model:{value:(_setup.verificationCode),callback:function ($$v) {_setup.verificationCode=$$v},expression:"verificationCode"}}),_c('div',{staticClass:"d-flex align-center"},[_c('v-progress-circular',{staticClass:"mr-1",attrs:{"rotate":-90,"size":16,"width":2,"value":_setup.percentageValue,"color":"blueRegular"}}),_c('span',{staticClass:"section-detail",style:({
                        cursor: _setup.percentageValue <= 0 ? 'pointer' : '',
                      }),attrs:{"disabled":true},on:{"click":function($event){_setup.percentageValue <= 0 ? _setup.resendToken() : null}}},[_vm._v(" "+_vm._s(_setup.t('Action_.buttons.resendCode'))+": "+_vm._s(_setup.convertValueToTime(_setup.percentageValue))+" ")])],1),_c('v-btn',{staticClass:"btn-regular",attrs:{"disabled":!_setup.valid,"loading":_setup.authenticationStore.isAuthenticationLoading,"id":"btn_access_kenbi","block":"","type":"submit"}},[_vm._v(_vm._s(_setup.t('Action_.buttons.accessKenbiBuro'))+" ")])],1)],1)],1)],1)],1)],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }