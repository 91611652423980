<template>
  <v-container class="pa-0" fill-height fluid>
    <v-row class="fill-height" no-gutters>
      <v-col cols="12">
        <v-container class="d-flex align-start fill-height">
          <backButton />
          <v-row>
            <v-col cols="12">
              <template v-if="getCurrentStep === 'insertConfirmationCode'">
                <AuthenticationValidateCode />
              </template>
              <template v-else-if="getCurrentStep === 'sendCodeConfirmation'">
                <AuthenticationSendCode />
              </template>
              <template v-else>
                <AuthenticationInputPhoneNumber />
              </template>
            </v-col>
          </v-row>
        </v-container>
      </v-col>
    </v-row>
  </v-container>
</template>

<script setup>
import AuthenticationInputPhoneNumber from './authentication-input-phone-number.vue';
import AuthenticationSendCode from './authentication-send-code.vue';
import AuthenticationValidateCode from './authentication-validate-code.vue';
import backButton from '../back-button.vue';
import { useAuthenticationStore } from '@/store/pinia/authentication.store.js';
import { computed } from 'vue';

const authenticationStore = useAuthenticationStore();

const getCurrentStep = computed(() => {
  if (
    authenticationStore.ownerName !== null &&
    authenticationStore.ownerPhoneNumber !== null &&
    authenticationStore.roleValidationToken !== null &&
    authenticationStore.resendToken !== null
  ) {
    return 'insertConfirmationCode';
  } else if (
    authenticationStore.ownerName !== null &&
    authenticationStore.ownerPhoneNumber !== null &&
    authenticationStore.roleValidationToken !== null
  ) {
    return 'sendCodeConfirmation';
  }
  return 'askNumber';
});
</script>
