import { useMainStore } from '@/store/pinia/main.store.js';
import { defineStore } from 'pinia';
import { ref } from 'vue';

export const useAuthenticationStore = defineStore('authentication', () => {
  const mainStore = useMainStore();

  // --- State ---
  const ownerName = ref(null);
  const ownerPhoneNumber = ref(null);
  const roleValidationToken = ref(null);
  const resendToken = ref(null);
  const isAuthenticationLoading = ref(false);
  const verifyToken = ref(null);
  const deviceCode = ref(null);
  const verificationUriComplete = ref(null);
  const expiresIn = ref(null);

  // --- Methods ---
  const getRoleValidationToken = async (data) => {
    const params = {
      endpoint: 'account/sms',
      configs: {
        method: 'POST',
        body: {
          PhoneNumber: data.PhoneNumber,
        },
      },
    };
    if (isAuthenticationLoading.value !== true) {
      isAuthenticationLoading.value = true;
    }
    try {
      const res = await mainStore.request(params);
      ownerName.value = res.ownerName;
      ownerPhoneNumber.value = res.phoneNumber;
      roleValidationToken.value = res.roleValidationToken;
    } catch (error) {
      return Promise.reject(error);
    } finally {
      isAuthenticationLoading.value = false;
    }
  };

  const getSmsVerificationToken = async () => {
    const data = {
      PhoneNumber: ownerPhoneNumber.value,
      RoleValidationToken: roleValidationToken.value,
    };

    const params = {
      endpoint: 'account/sms/send',
      configs: {
        method: 'POST',
        body: {
          ...data,
        },
      },
    };
    if (isAuthenticationLoading.value !== true) {
      isAuthenticationLoading.value = true;
    }
    try {
      const res = await mainStore.request(params);
      resendToken.value = res.resend_token;
      if (process.env.VUE_APP_AUTO_APPLY_SMS_TOKEN === 'true') {
        verifyToken.value = res.verify_token;
      }
    } catch (error) {
      return Promise.reject(error);
    } finally {
      isAuthenticationLoading.value = false;
    }
  };

  const getNewSMSToken = async () => {
    const data = {
      phoneNumber: ownerPhoneNumber.value,
      resend_token: resendToken.value,
    };

    const params = {
      endpoint: 'account/sms/resend',
      configs: {
        method: 'POST',
        body: {
          ...data,
        },
      },
    };
    if (isAuthenticationLoading.value !== true) {
      isAuthenticationLoading.value = true;
    }

    try {
      const res = await mainStore.request(params);
      resendToken.value = res.resend_token;
    } catch (error) {
      return Promise.reject(error);
    } finally {
      isAuthenticationLoading.value = false;
    }
  };

  const getAccessTokenByPhoneNumber = async (verificationToken) => {
    const data = {
      phoneNumber: ownerPhoneNumber.value,
      verify_token: verificationToken,
    };

    const params = {
      endpoint: 'account/sms/token',
      configs: {
        method: 'POST',
        body: {
          ...data,
        },
      },
    };
    if (isAuthenticationLoading.value !== true) {
      isAuthenticationLoading.value = true;
    }

    try {
      const res = await mainStore.request(params);
      // cookie now?
      console.warn(res);
    } catch (error) {
      return Promise.reject(error);
    } finally {
      isAuthenticationLoading.value = false;
    }
  };

  const getQrCode = async () => {
    const params = {
      endpoint: 'account/qrcode',
      configs: {
        method: 'POST',
      },
    };
    if (isAuthenticationLoading.value !== true) {
      isAuthenticationLoading.value = true;
    }

    try {
      const res = await mainStore.request(params);
      deviceCode.value = res.deviceCode;
      verificationUriComplete.value = res.verificationUrl;
      expiresIn.value = res.expiresIn;
    } catch (error) {
      return Promise.reject(error);
    } finally {
      isAuthenticationLoading.value = false;
    }
  };

  const getQrCodeAccessApproval = async () => {
    const data = {
      deviceCode: deviceCode.value,
    };

    const params = {
      endpoint: 'account/qrcode/token',
      configs: {
        method: 'POST',
        body: {
          ...data,
        },
      },
    };

    try {
      const res = await mainStore.request(params);
      // cookie now?
      console.warn(res);
      return res;
    } catch (error) {
      return Promise.reject(error);
    }
  };

  return {
    ownerName,
    ownerPhoneNumber,
    roleValidationToken,
    resendToken,
    isAuthenticationLoading,
    verifyToken,
    deviceCode,
    verificationUriComplete,
    expiresIn,
    getRoleValidationToken,
    getSmsVerificationToken,
    getNewSMSToken,
    getAccessTokenByPhoneNumber,
    getQrCode,
    getQrCodeAccessApproval,
  };
});
