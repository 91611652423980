<template>
  <v-container class="pa-0" fill-height fluid>
    <v-row class="fill-height" no-gutters>
      <v-col cols="12">
        <v-container class="d-flex align-start fill-height">
          <v-row>
            <v-col cols="12">
              <v-card width="500px" class="pl-15 mb-16" elevation="0">
                <v-card-title class="login-title">
                  {{ t('Title_.page.loginPhoneNumber') }}
                </v-card-title>
                <v-card-subtitle class="login-subtitle mb-16">
                  {{ t('Title_.subTitle.loginPhoneViewInstructions') }}
                </v-card-subtitle>
                <v-card-text>
                  <v-form
                    v-model="valid"
                    @submit.prevent="validatePhoneNumber()"
                    :disabled="authenticationStore.isAuthenticationLoading"
                  >
                    <v-container>
                      <v-row>
                        <v-col cols="2" class="mr-1">
                          <v-menu transition="slide-y-transition" bottom>
                            <template #activator="{ on, attrs }">
                              <v-btn
                                outlined
                                elevation="0"
                                v-bind="attrs"
                                v-on="on"
                                id="selected-country-btn"
                              >
                                {{ selectedCountryCode }}
                              </v-btn>
                            </template>
                            <v-list>
                              <v-list-item
                                v-for="(item, i) in countryCodeList"
                                @click="updatePhoneCode(item)"
                                :key="i"
                                link
                                id="country-code-list-item"
                              >
                                {{ item }}
                              </v-list-item>
                            </v-list>
                          </v-menu>
                        </v-col>
                        <v-col>
                          <v-text-field
                            v-model="phoneNumber"
                            :error-messages="validationErrorMessage"
                            :error="showBackendValidationError"
                            clearable
                            dense
                            :rules="[
                              $rules.fieldRequired(
                                t('Msg_.rules.phoneNumberRequired')
                              ),
                              $rules.phoneNumber(
                                t('Msg_.rules.totalDigits', ['9', '12'])
                              ),
                            ]"
                            required
                            :label="t('Msg_.kenbiMobile') + '*'"
                            :placeholder="t('Msg_.phoneNumber')"
                            outlined
                            maxlength="12"
                            minlength="9"
                            @keyup="onlyNumbers()"
                          >
                          </v-text-field>
                        </v-col>
                      </v-row>
                    </v-container>
                    <v-btn
                      id="btn-next"
                      :disabled="!valid"
                      :loading="authenticationStore.isAuthenticationLoading"
                      class="btn-regular"
                      block
                      elevation="2"
                      type="submit"
                    >
                      <span>{{ t('Action_.buttons.next') }}</span>
                    </v-btn>
                  </v-form>
                </v-card-text>
              </v-card>
            </v-col>
          </v-row>
        </v-container>
      </v-col>
    </v-row>
  </v-container>
</template>

<script setup>
import languages from '../locales/languages';
import { useAuthenticationStore } from '@/store/pinia/authentication.store.js';
import { ref } from 'vue';
import { useI18n } from 'vue-i18n-bridge';

const authenticationStore = useAuthenticationStore();
const { t } = useI18n({ locale: 'en', messages: languages });

const valid = ref(false);
const phoneNumber = ref(null);
const selectedCountryCode = ref('+49');
const countryCodeList = ref(['+49', '+41', '+351']);
const showBackendValidationError = ref(false);
const validationErrorMessage = ref(null);

const onlyNumbers = () => {
  phoneNumber.value = phoneNumber.value.replace(/\D/g, '');
};

const updatePhoneCode = (code) => {
  selectedCountryCode.value = code;
};

const validatePhoneNumber = async () => {
  if (
    (phoneNumber.value.length > 8 || phoneNumber.value.length < 11) &&
    valid.value
  ) {
    let fullNumber = selectedCountryCode.value + phoneNumber.value;
    // const toastParams = {
    //   type: 'success',
    //   messages: {
    //     success: t('Msg_.success'),
    //     error: t('Msg_.error'),
    //   },
    // };
    // this.$root.message.setToastParams(toastParams);
    try {
      await authenticationStore.getRoleValidationToken({
        PhoneNumber: fullNumber,
      });
      // this.$root.message.showToastNotification();
    } catch (error) {
      console.log(error);
    }
  }
};
</script>

<style scoped lang="scss">
.v-text-field ::v-deep label {
  font-size: 13px;
}

.v-input--is-focused ::v-deep fieldset {
  border: 1px solid $blue-regular !important;
}

.v-text-field--outlined ::v-deep fieldset {
  border-color: $ash-dark;
}

#btn-next {
  margin-top: 90px;
  padding: 1.5rem 0;
}

#selected-country-btn {
  font-size: 13px;
  font-weight: 400;
  border: 1px solid $ash-dark !important;
  color: $grey-dark !important;
  background-color: #fff !important;
  height: 40px;
}

#country-code-list-item {
  font-weight: 300 !important;
  font-size: 13px !important;
  letter-spacing: 0.4px;
  color: $mine-black;
}
</style>
