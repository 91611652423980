var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('v-container',{staticClass:"pa-0",attrs:{"fill-height":"","fluid":""}},[_c('v-row',{staticClass:"fill-height",attrs:{"no-gutters":""}},[_c('v-col',{attrs:{"cols":"12"}},[_c('v-container',{staticClass:"d-flex align-start fill-height"},[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-card',{staticClass:"pl-15 mb-16",attrs:{"width":"500px","elevation":"0"}},[_c('v-card-title',{staticClass:"login-title"},[_vm._v(" "+_vm._s(_setup.t('Title_.page.loginPhoneNumber'))+" ")]),_c('v-card-subtitle',{staticClass:"login-subtitle mb-16"},[_vm._v(" "+_vm._s(_setup.t('Title_.subTitle.loginPhoneViewInstructions'))+" ")]),_c('v-card-text',[_c('v-form',{attrs:{"disabled":_setup.authenticationStore.isAuthenticationLoading},on:{"submit":function($event){$event.preventDefault();return _setup.validatePhoneNumber()}},model:{value:(_setup.valid),callback:function ($$v) {_setup.valid=$$v},expression:"valid"}},[_c('v-container',[_c('v-row',[_c('v-col',{staticClass:"mr-1",attrs:{"cols":"2"}},[_c('v-menu',{attrs:{"transition":"slide-y-transition","bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('v-btn',_vm._g(_vm._b({attrs:{"outlined":"","elevation":"0","id":"selected-country-btn"}},'v-btn',attrs,false),on),[_vm._v(" "+_vm._s(_setup.selectedCountryCode)+" ")])]}}])},[_c('v-list',_vm._l((_setup.countryCodeList),function(item,i){return _c('v-list-item',{key:i,attrs:{"link":"","id":"country-code-list-item"},on:{"click":function($event){return _setup.updatePhoneCode(item)}}},[_vm._v(" "+_vm._s(item)+" ")])}),1)],1)],1),_c('v-col',[_c('v-text-field',{attrs:{"error-messages":_setup.validationErrorMessage,"error":_setup.showBackendValidationError,"clearable":"","dense":"","rules":[
                            _vm.$rules.fieldRequired(
                              _setup.t('Msg_.rules.phoneNumberRequired')
                            ),
                            _vm.$rules.phoneNumber(
                              _setup.t('Msg_.rules.totalDigits', ['9', '12'])
                            ),
                          ],"required":"","label":_setup.t('Msg_.kenbiMobile') + '*',"placeholder":_setup.t('Msg_.phoneNumber'),"outlined":"","maxlength":"12","minlength":"9"},on:{"keyup":function($event){return _setup.onlyNumbers()}},model:{value:(_setup.phoneNumber),callback:function ($$v) {_setup.phoneNumber=$$v},expression:"phoneNumber"}})],1)],1)],1),_c('v-btn',{staticClass:"btn-regular",attrs:{"id":"btn-next","disabled":!_setup.valid,"loading":_setup.authenticationStore.isAuthenticationLoading,"block":"","elevation":"2","type":"submit"}},[_c('span',[_vm._v(_vm._s(_setup.t('Action_.buttons.next')))])])],1)],1)],1)],1)],1)],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }