<template>
  <v-row class="align-self-start">
    <v-col cols="12" id="go_back_text">
      <div>
        <a
          class="d-flex align-center"
          id="back-btn-container"
          data-testid="back-btn"
          @click="$router.push({ name: 'Authentication' })"
        >
          <img
            src="@/assets/authentication/arrow-left.svg"
            alt="arrow-left"
            id="img_arrow_left"
          />
          <span> {{ t('Action_.buttons.goBack') }} </span>
        </a>
      </div>
    </v-col>
  </v-row>
</template>

<script setup>
import languages from './locales/languages';
import { useI18n } from 'vue-i18n-bridge';

const { t } = useI18n({ locale: 'en', messages: languages });
</script>

<style scoped lang="scss">
#go_back_text {
  padding-left: 3.5rem;
  padding-top: 3.5rem;

  a {
    width: 7.5rem;
  }

  #img_arrow_left {
    margin-right: 0.5rem;
  }
}
</style>
