<template>
  <v-container class="pa-0" fill-height fluid>
    <v-row class="fill-height" no-gutters>
      <v-col cols="12">
        <v-container class="d-flex align-start fill-height">
          <v-row>
            <v-col cols="12">
              <v-card width="500px" class="pl-15 mb-16" elevation="0">
                <v-card-title class="login-title">
                  {{ t('Title_.page.loginPhoneNumber') }}
                </v-card-title>
                <v-card-subtitle class="login-subtitle mb-16">
                  {{ t('Title_.subTitle.loginValidateCode') }}
                  <strong> {{ ownerPhone }} </strong>
                </v-card-subtitle>
                <v-card-text>
                  <v-form
                    v-model="valid"
                    @submit.prevent="loginWithPhoneNumber()"
                    :disabled="authenticationStore.isAuthenticationLoading"
                  >
                    <v-text-field
                      v-model="verificationCode"
                      :error="showBackendValidationError"
                      :rules="[
                        $rules.fieldRequired(
                          t('Msg_.rules.verificationCode') + '*'
                        ),
                        $rules.totalSize(6, t('Msg_.rules.codeTotalDigits')),
                      ]"
                      clearable
                      required
                      :label="t('Msg_.verificationCode')"
                      :placeholder="t('Msg_.verificationCode')"
                      outlined
                      maxlength="6"
                      @keyup="onlyNumbers"
                    >
                    </v-text-field>
                    <div class="d-flex align-center">
                      <v-progress-circular
                        class="mr-1"
                        :rotate="-90"
                        :size="16"
                        :width="2"
                        :value="percentageValue"
                        color="blueRegular"
                      >
                      </v-progress-circular>
                      <span
                        class="section-detail"
                        :disabled="true"
                        :style="{
                          cursor: percentageValue <= 0 ? 'pointer' : '',
                        }"
                        @click="percentageValue <= 0 ? resendToken() : null"
                      >
                        {{ t('Action_.buttons.resendCode') }}:
                        {{ convertValueToTime(percentageValue) }}
                      </span>
                    </div>
                    <v-btn
                      :disabled="!valid"
                      :loading="authenticationStore.isAuthenticationLoading"
                      id="btn_access_kenbi"
                      class="btn-regular"
                      block
                      type="submit"
                      >{{ t('Action_.buttons.accessKenbiBuro') }}
                    </v-btn>
                  </v-form>
                </v-card-text>
              </v-card>
            </v-col>
          </v-row>
        </v-container>
      </v-col>
    </v-row>
  </v-container>
</template>

<script setup>
import { useAuthenticationStore } from '@/store/pinia/authentication.store.js';
import { ref, computed, onBeforeMount, onBeforeUnmount } from 'vue';
import { useI18n } from 'vue-i18n-bridge';
import languages from '@/components/authentication/locales/languages';

const authenticationStore = useAuthenticationStore();
const { t } = useI18n({ locale: 'en', messages: languages });

const countdownStop = ref(false);
const interval = ref({});
const percentageValue = ref(100);
const verificationCode = ref(null);
const valid = ref(false);
const showBackendValidationError = ref(false);

const loginWithPhoneNumber = async () => {
  // const t = this.$t.bind(this);
  // const toastParams = {
  //   messages: {
  //     error: t('Msg_.error'),
  //   },
  // };
  // this.$root.message.setToastParams(toastParams);
  try {
    await authenticationStore.getAccessTokenByPhoneNumber(
      verificationCode.value
    );
    clearAuthenticationInfo();
    window.location.href = `${process.env.VUE_APP_LOGIN_API}`;
  } catch (error) {
    console.log(error);
  }
};

const resendToken = () => {
  if (countdownStop.value && percentageValue.value <= 0) {
    countdownStop.value = false;
    percentageValue.value = 100;
    authenticationStore.getNewSMSToken({});
  }
};

const convertValueToTime = (value) => {
  if (value != 0) {
    const sec = parseInt(value, 10);
    let hours = Math.floor(sec / 3600);
    let minutes = Math.floor((sec - hours * 3600) / 60);
    let seconds = sec - hours * 3600 - minutes * 60;

    if (hours < 10) {
      hours = '0' + hours;
    }
    if (seconds < 10) {
      seconds = '0' + seconds;
    }
    return minutes + ':' + seconds;
  }
  return 'Ready';
};

const onlyNumbers = () => {
  verificationCode.value = verificationCode.value.replace(/\D/g, '');
};

const clearAuthenticationInfo = () => {
  authenticationStore.ownerName = null;
  authenticationStore.ownerPhoneNumber = null;
  authenticationStore.roleValidationToken = null;
  authenticationStore.resendToken = null;
  authenticationStore.verifyToken = null;
};

const ownerPhone = computed(() => {
  let phone = authenticationStore.ownerPhoneNumber
    .replace(/[^\dA-Z,+]/g, '')
    .replace(/(.{3})/g, '$1 ')
    .trim();
  return phone;
});

// --- Lifecycle hooks ---
onBeforeMount(() => {
  if (authenticationStore.verifyToken) {
    verificationCode.value = authenticationStore.verifyToken;
  }
  interval.value = setInterval(() => {
    if (!countdownStop.value) {
      if (percentageValue.value <= 0) {
        countdownStop.value = true;
        percentageValue.value = 0;
      } else {
        percentageValue.value -= 1;
      }
    }
  }, 1000);
});

onBeforeUnmount(() => {
  interval.value = null;
  clearAuthenticationInfo();
});
</script>

<style scoped lang="scss">
.v-text-field--outlined ::v-deep fieldset {
  border-color: $ash-dark;
}

.v-text-field ::v-deep label {
  font-size: 13px;
}

#btn_access_kenbi {
  margin-top: 90px;
  border-radius: 6px;
  padding: 1.5rem 0;
}
</style>
