<template>
  <v-container class="pa-0" fill-height fluid>
    <v-row class="fill-height" no-gutters>
      <v-col cols="12">
        <v-container class="d-flex align-start fill-height">
          <v-row>
            <v-col cols="12">
              <v-card width="500px" class="pl-15 mb-16" elevation="0">
                <v-card-title class="login-title">
                  {{ t('Title_.page.loginPhoneNumber') }}
                </v-card-title>
                <v-card-subtitle class="login-subtitle mb-16">
                  {{ t('Title_.subTitle.loginSendCode[0]') }}
                  <strong>
                    {{ t('Title_.subTitle.loginSendCode[1]', [ownerPhone]) }}
                  </strong>
                  {{ t('Title_.subTitle.loginSendCode[2]') }}
                </v-card-subtitle>
                <v-card-text>
                  <div class="d-flex align-center" id="user">
                    <img
                      class="mr-3"
                      src="@/assets/authentication/auth_avatar.svg"
                      alt="avatar"
                    />
                    <span class="section-detail">{{
                      authenticationStore.ownerName
                    }}</span>
                  </div>
                  <v-btn
                    @click="sendCode()"
                    :loading="authenticationStore.isAuthenticationLoading"
                    id="btn-send-code"
                    class="mb-3 btn-regular"
                    block
                  >
                    <span> {{ t('Action_.buttons.sendCode') }} </span>
                  </v-btn>
                  <v-btn
                    :disabled="authenticationStore.isAuthenticationLoading"
                    @click="clearAuthenticationInfo()"
                    id="btn-not-me"
                    class="btn-text"
                    color="redDanger"
                    outlined
                    block
                  >
                    <span>{{ t('Action_.buttons.notMe') }}</span>
                  </v-btn>
                </v-card-text>
              </v-card>
            </v-col>
          </v-row>
        </v-container>
      </v-col>
    </v-row>
  </v-container>
</template>

<script setup>
import languages from '@/components/authentication/locales/languages';
import { useAuthenticationStore } from '@/store/pinia/authentication.store.js';
import { computed } from 'vue';
import { useI18n } from 'vue-i18n-bridge';

const authenticationStore = useAuthenticationStore();
const { t } = useI18n({ locale: 'en', messages: languages });

// --- Methods ---
const sendCode = async () => {
  // const t = this.$t.bind(this);
  // const toastParams = {
  //   type: 'success',
  //   messages: {
  //     success: t('Msg_.smsTokenSent'),
  //     error: t('Msg_.error'),
  //   },
  // };
  // this.$root.message.setToastParams(toastParams);
  try {
    await authenticationStore.getSmsVerificationToken();
    // this.$root.message.showToastNotification();
  } catch (error) {
    console.log(error);
  }
};

const clearAuthenticationInfo = () => {
  authenticationStore.ownerName = null;
  authenticationStore.ownerPhoneNumber = null;
  authenticationStore.roleValidationToken = null;
};

const ownerPhone = computed(() => {
  let phone = authenticationStore.ownerPhoneNumber
    .replace(/[^\dA-Z,+]/g, '')
    .replace(/(.{3})/g, '$1 ')
    .trim();
  return phone;
});
</script>

<style lang="scss" scoped>
%btn {
  border-radius: 6px;
  padding: 1.5rem 0;
}

#user {
  img {
    width: 23px;
    height: auto;
  }

  span {
    font-size: 20px;
  }
}

#btn-send-code {
  @extend %btn;
  margin-top: 90px;

  span {
    color: white;
  }
}

#btn-not-me {
  @extend %btn;
}
</style>
